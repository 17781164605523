import React from "react"
import Layout from "../../layout/layout"
import style from "./privacy-policy.module.scss"

const PrivacyPolicyPage = () => {
  return (
    <Layout title="Privacy Policy" darkHeader className={style.privacyPolicy}>
      <section>
        <h1>Privacy Policy of OCD.</h1>
          <p>OCD (we/us) are committed to protecting your privacy, including your personal data. We will be the data controller of any personal data which we collect from or about you. This Policy sets out the terms on which we will hold and process your personal data. If you have any queries about this Policy or how we use your data, you can contact us using the contact details below.</p>
          <p>Please read this Policy carefully. By contracting with us, you acknowledge that your personal data will be used in accordance with this Policy.</p>
        
        <h2>When Do We Collect Information?</h2>
          <p>We will collect information from you when you contact us in person, by telephone, by e-mail, by post or by social media. We also collect information from you when you voluntarily complete customer surveys, enter a competition or promotion, sign up for our mailing list or provide feedback or complete a contact form on our website.</p>
          <p>Information may also be collected about you when you visit our website (for example via cookies), such as your IP address and other browser-generated information.</p>
        
        <h2>What Information Will We Collect?</h2>
          <p>We may collect the following information:-</p>
          <p>
            <ul>
            <li>Your contact details or those of your employees, such as name, address, telephone number and email address</li>
            </ul>
          </p>
        
        <h2>How We Will Use Your Information</h2>
          <p>We use information held about you in the following ways:-</p>
          <p>
            <ul>
              <li>to help protect your information and prevent unauthorised access to it;</li>
              <li>to deal with any queries, complaints or problems reported by you;</li>
              <li>to enable you to participate in our promotions or competitions;</li>
              <li>to enable you to participate in the interactive features of our website and to ensure that content from our website is presented in the most effective manner for you and your computer/device;</li>
              <li>to generate statistics relating to use of our website;</li>
              <li>to provide you with information about other goods we offer. You may opt out of receiving this information when we collect your details or at any time by contacting us using the contact details below;</li>
              <li>if required to do so by law and to the extent necessary for the proper operation of our systems, to protect us/our customers, or to enforce the terms of any contract that we have entered into with you;</li>
              <li>to notify you of changes to our terms of business;</li>
            </ul>
          </p>
          <p>Our use of your personal data will be for one or more of the legitimate interests described above.</p>

        <h2>Disclosure of Your Information</h2>
          <p>We may disclose your information:-</p>
          <p>
            <ul>
              <li>to businesses that are part of the same group of companies as us, or that become part of that group;</li>
              <li>if required to do so by any legal requirement;</li>
              <li>to HMRC if obliged to do so by law, who may pass this on to tax authorities in other jurisdictions; or</li>
              <li>if we or substantially all of our assets are acquired by a third party, in which case personal data held by us about our customers and suppliers may be one of the transferred assets.</li>
              <li>We will not disclose your information to third parties other than as outlined above.</li>
            </ul>
          </p>

        <h2>Storage of Your Personal Data</h2>
          <p>We will only keep your personal data for as long as we need to in order to fulfil the purpose(s) for which it was collected, as set out above in this Policy, and for as long as we are required to keep it by law.</p>
          <p>We take appropriate security measures (including physical, electronic and procedural measures) to help safeguard your personal information from unauthorised access and disclosure.</p>
          <p>Data collected for mailing purposes is stored within our email marketing software: MailChimp. Details of how the data is stored can be found on their website: <a href="https://mailchimp.com/legal/privacy/" rel="noopener noreferer" target="_blank">https://mailchimp.com/legal/privacy/</a></p>

          <p>You can update your personal data and preferences on how we store it by contacting us or editing your preferences via the link in our marketing emails.</p>

        <h2>Cookies</h2>
          <p>This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyse and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.</p>
        
        <h2>Your Rights</h2>
          <p>You have the following rights which can be exercised by contacting us using the details provided below:-</p>
          <p>The right:</p>
          <p>
            <ul>
              <li>to ask us not to process your personal data for marketing purposes;</li>
              <li>to access personal information held about you and to obtain a copy of it</li>
              <li>to prevent any processing of personal data that is causing or is likely to cause unwarranted and substantial damage or distress to you or another individual;</li>
              <li>to obtain the rectification or completion of personal data which are inaccurate or incomplete;</li>
              <li>to restrict or object to the processing of your personal data and to request its erasure under certain circumstances;</li>
              <li>to be informed about any use of your personal data to make automated decisions about you, and to obtain meaningful information about the logic involved, as well as the significance and the envisaged consequences of this processing;</li>
              <li>to lodge a complaint about the way in which your personal data is being used; and</li>
              <li>to withdraw your consent to any use by us of your personal data otherwise than for one of the legitimate purposes set out above.</li>
            </ul>
          </p>

        <h2>Changes to Our Privacy Policy</h2>
          <p>We reserve the right to amend this Policy at any time. Any substantive changes we make to our Policy in the future will be notified to you in writing, but we would advise you to check our website regularly to ensure that you are familiar with the most up-to-date version.</p>

        <h2>Contacting Us</h2>
          <p>If you have any queries, comments or requests regarding this Policy or would like to exercise any of your rights set out above, you can contact us at:-</p>
          <p>Email: <a href="mailto:sales@ocdonline.co.uk">sales@ocdonline.co.uk</a></p>
          <p>Post: Odour Control Developments, 1D Croxstalls Rd, Bloxwich, Walsall WS3 2XU</p>
          <p>If we cannot resolve your concerns, you may report a concern to the Information Commissioner at <a href="https://www.ico.org.uk/concerns" rel="noopener noreferer" target="_blank">ico.org.uk/concerns</a> or telephone (0303) 123 1113 for further information about your rights.</p>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
